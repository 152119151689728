@import '../../../../src/scss/variables';

.tooltip {
  position: absolute;

  width: 400px; /* max width */
  left: 50%;
  transform: translateX(-50%);
  bottom: calc(100% + 8px + 7px); /* height of item + height of arrow + extra margin */

  /* Center tooltip inside absolute positioned container */
  display: flex;
  justify-content: center;
  font-weight: initial;
  pointer-events: none !important;
  z-index: 20000;

  &.visible {
    visibility: visible;
    opacity: 1;
    transition: opacity 150ms linear;
  }
  &.hidden {
    visibility: hidden;
    opacity: 0;
  }

  > div {
    color: #fff;
    background-color: $brand-gray-1;
    padding: 15px;

    font-size: 12px;
    font-family: 'montserrat';
    line-height: initial;
    text-align: left;

    /* Only direct children (ng-transclude will add an extra span) */
    > span {
      /* Title */
      &:nth-child(1) {
        font-weight: bold;
        display: block;
        text-transform: uppercase;
      }

      /* Text */
      &:nth-child(2) {
        display: block;
        margin-top: 10px;
      }
    }

    /* Arrow */
    &::after {
      content: '';
      position: absolute;
      top: 100%;
      left: 50%;
      margin-left: -8px;
      border-width: 8px;
      border-style: solid;
      border-color: $brand-gray-1 transparent transparent transparent;
    }
  }
}
