.canvas-thumbnail {
  position: absolute;
  background-color: rgba(64, 64, 64, 0.8);
  width: 150px;
  height: 100px;
  color: white;
  border: 2px solid #000;
  pointer-events: none;

  z-index: 999;
}
