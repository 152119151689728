.button:hover {
  color: $brand-gray-4 !important; // need to force color, because specialized button styles have higher priority
  background-color: $brand-gray-1;
  transition: background-color 0.3s ease-in;
}

.button,
input[type='button'],
input[type='submit'] {
  white-space: nowrap;
}
