@import '../../../../../src/scss/variables';

$marker-margin-top: -34px;
$marker-margin-left: -5px;

.marker-editor-container {
  background: #ccddf0;
  border-radius: 4px;
  height: 100%;
  overflow: hidden;
  margin-left: 8px;
  cursor: none;
}

.overview-button {
  position: absolute;
  z-index: 100;
  margin: 10px 0 0 80px;
}

.disable-photo-button {
  position: absolute;
  z-index: 100;
  margin: 10px;
  margin: 10px 0 0 200px;
}

.marker-editor-image-container {
  position: relative;
  margin-top: 60px;
}

.marker-editor-image {
  width: 100%;
  height: 100%;
}

.marker-editor-align-invalid-text {
  background: $brand-yellow-3;
  color: black;
  border: solid $brand-yellow-3;
  border-radius: 5px;
  font-family: 'montserrat';
  margin-top: 60px;
  width: 40%;
}

.marker-container {
  position: absolute;
  pointer-events: none;
}

.marker-flag {
  position: absolute;
  margin-top: $marker-margin-top;
  margin-left: $marker-margin-left;
  color: #1bbbff;
  font-size: 21pt;
}

.marker-flag-shadow {
  position: absolute;
  margin-top: calc(#{$marker-margin-top} + 1px);
  margin-left: calc(#{$marker-margin-left} + 1px);
  color: black;
  font-size: 21pt;
}

.marker-pinned {
  color: #67ff1b;
}

.marker-text {
  position: absolute;
  width: max-content;
  left: 100%;
  font-size: 21px;
  opacity: 1;
  border: 1px solid rgb(240, 237, 220);
  background: rgb(240, 237, 220);
}

.marker-dot {
  position: absolute;
  width: 10px;
  height: 10px;
  top: -5px;
  left: -5px;
  border: 1px solid black;
  border-radius: 5px;
  background: $brand-yellow-1;
}

.marker-image-container {
  width: -webkit-fill-available;
  height: -webkit-fill-available;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}

.marker-editor-cursor {
  position: fixed;
  width: 10px;
  height: 10px;
  pointer-events: none;
  z-index: 10;
}

.marker-editor-cursor-circle-white {
  position: absolute;
  width: 120px;
  height: 120px;
  left: -60px;
  top: -60px;
  border-radius: 60px;
  border: 1px solid white;
  pointer-events: none;
}

.marker-cursor-text {
  top: -40px;
  left: 40px;
}

.marker-editor-cursor-cross-white {
  background: white;
  transform: rotate(45deg) + scale(1.2);
}

.marker-editor-cursor-cross-black {
  background: black;
  transform: scale(1.05);
}

.marker-editor-cursor-line-1 {
  position: absolute;
  width: 40px;
  height: 1px;
  left: 8px;
  top: -1px;
  background: inherit;
  pointer-events: none;
}

.marker-editor-cursor-line-2 {
  position: absolute;
  width: 40px;
  height: 1px;
  left: -48px;
  top: -1px;
  background: inherit;
  pointer-events: none;
}

.marker-editor-cursor-line-3 {
  position: absolute;
  width: 1px;
  height: 40px;
  left: -1px;
  top: -48px;
  background: inherit;
  pointer-events: none;
}

.marker-editor-cursor-line-4 {
  position: absolute;
  width: 1px;
  height: 40px;
  left: -1px;
  top: 8px;
  background: inherit;
  pointer-events: none;
}
