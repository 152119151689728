$blur-size: 5px;

#reconstruction {
  p {
    margin: 0;
    font-size: 12pt;
    line-height: 1.4em;
    text-align: center;
    margin-bottom: 20px;
  }
}
