@import '../../../../src/scss/variables';

// todo: Can we set dialog width when creating popeye dialog object instead?
.create-project-dialog {
  .popeye-wrapper {
    max-width: 500px;
    width: 500px;
  }
}

.create-project-container {
  input {
    flex: 4 0 auto;
    border: 1px solid $brand-gray-2;
    border-radius: 4px;
    padding: 16px;
    font-family: $font-family-header;
  }

  label {
    display: block;
    margin-bottom: 6px;
  }

  input[type='text'] {
    width: 100%;
  }

  .company-name {
    margin-bottom: 15px;
  }

  footer {
    margin: 30px 0 0 0 !important;
    justify-content: flex-end !important;

    button {
      margin: 0;
    }
  }
}
