@import '../../../../src/scss/variables';

.photo-inspector-container {
  background-color: $brand-gray-4;
  border: solid 1px $brand-gray-3;
  border-radius: 4px 0 0 4px;
  height: 100%;
  width: calc(100% - 208px);
  overflow: hidden;
}

.photo-inspector-backdrop {
  position: absolute;
  background-color: rgba(64, 64, 64, 0.8);
  width: 150%;
  height: 150%;
  top: -10px;
  left: -10px;
  opacity: 0.8;

  z-index: 9991;
}

.photo-inspector-window {
  position: absolute;
  left: 15%;
  top: 10%;
  width: 75%;
  height: 80%;

  z-index: 9992;
}

.photo-inspector-modal-contents {
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
}

.photo-inspector-thumbnail-list-container {
  background-color: $brand-gray-4;
  border: solid 1px $brand-gray-3;
  border-radius: 0 4px 4px 0;
  width: 208px;
}

.photo-inspector-thumbnail-list {
  background-color: $brand-gray-3;
  height: calc(100% - 50px);
  border: 3px solid $brand-gray-3;
  overflow-y: auto;
}

.photo-inspector-image-container {
  position: relative;
  margin-top: 60px;
}

.photo-inspector-image {
  width: 100%;
  height: 100%;
}

.photo-inspector-close-button {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 95px;
  margin: 5px;
  z-index: 1;
}
.photo-inspector-saveimage-button {
  position: absolute;
  right: 102px;
  bottom: 0;
  width: 95px;
  margin: 5px;
  z-index: 1;
}

.photo-inspector-toolbar {
  z-index: 1;
  display: flex;
  background-color: $brand-gray-4;
  border: 1px solid $brand-gray-2;
  border-radius: 10px;
  margin: 5px;
  padding: 5px;
}

.photo-inspector-item {
  font-size: 32px;

  margin-left: 2px;
  margin-right: 2px;
  padding-left: 2px;
  padding-right: 2px;

  color: $brand-gray-1;
  border-color: $brand-gray-1;

  transition-duration: 0.2s;
}

.photo-inspector-icon {
  width: 32px;
  height: 32px;
}

.photo-inspector-item:hover {
  color: $brand-gray-1;
  background-color: $brand-gray-4;
  cursor: pointer;

  border-radius: 4px;

  transition-duration: 0.2s;
}

.photo-inspector-item.active {
  box-shadow: 0 0 10px $brand-gray-1;

  color: white;
  background-color: $brand-gray-1;
  border-color: white;
  cursor: pointer;

  border-radius: 4px;
}

.photo-inspector-color-palette {
  width: 80%;
  height: 80%;
  margin: 10%;
  border: 2px solid;
}

.inspection-tool-marking-item {
  width: 80%;
  height: 80%;
  border: 2px dashed;
  border-radius: 2px;
  margin: 10%;
}

.photo-inspector-loading-container {
  position: absolute;
  left: 50%;
  top: 40%;
  pointer-events: none;
}

.photo-inspector-loading-content {
  left: -50%;
  position: relative;
}

.photo-inspector-loading-text {
  font-size: 30px;
  font-family: 'montserrat';
  text-align: center;
}
