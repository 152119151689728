@import '../../../../src/scss/variables';

.date-time-picker-element {
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;

  input {
    flex: 1;
    pointer-events: none;
    width: 100%;
    padding-right: 20px !important; // Width of calendar icon
  }

  i {
    position: absolute;
    right: 0;
    padding: 0 10px;
    cursor: pointer;
    font-size: 1.1em;
  }
}
