:root[data-theme='default'] {
  //
  // FULL PAGE BOX COMPONENT
  //
  --full-page-box-background: url('/static/themes/default/images/background.jpg') center center
    no-repeat;
  --full-page-box-logotype: url('/static/themes/default/images/logotype.png');

  //
  // CAD VIEWER
  //
  // Header
  --top-menu-background-color: #5e6a71;
  --top-menu-logotype: url('/static/assets/img/navbar_logo.png');
  --top-menu-text-color: #fff;
}
