@import '../../../../../src/scss/variables';

.thumbnail-list-container {
  background: $brand-gray-4;
  border-radius: 4px;
  height: 100%;
  display: flex;
  flex-direction: column;
  margin-left: 8px;
  padding: 2px;
}

.thumbnail-list-header {
  //style="position: sticky;top: 0;background: white;z-index: 100;
  flex: 0 0 auto;
  margin: 4px;
}

.thumbnail-list-body {
  //style="position: sticky;top: 0;background: white;z-index: 100;
  flex: 1 1 auto;
  overflow-x: hidden;
  overflow-y: auto;
  width: 100%;
}

.thumbnail-list-toggle-button {
  background: $brand-gray-3;
  text-align: center;
}

.thumbnail-list-toggle-button:hover {
  background: $brand-gray-2;
  transition: background-color 0.3s ease-in;
}

.thumbnail-list-toggle-button-toggled {
  background: $brand-yellow-1;
}

.thumbnail-list-toggle-button-toggled:hover {
  background: $brand-yellow-2;
  transition: background-color 0.3s ease-in;
}

.thumbnail-card {
  width: 95%;
  height: 100px;
  border-radius: 2px;
  align-items: center;
  margin-top: 4px;
  margin-left: 4px;
  border: 8px solid $brand-gray-2;
}

.thumbnail-contains-pinned {
  color: #67ff1b;
  position: absolute;
  bottom: 54px;
  font-size: 24px;
  margin: 3px;
}

.thumbnail-not-aligned {
  color: $brand-yellow-1;
  position: absolute;
  bottom: 50px;
  right: 0px;
  font-size: 32px;
  margin-top: 3px;
  margin-right: 8px;
}

.thumbnail-contains-unpinned {
  color: #1bbbff;
  position: absolute;
  bottom: 1px;
  font-size: 24px;
  margin: 3px;
}

.thumbnail-contains-unpinned-shadow {
  color: black;
  position: absolute;
  bottom: 0px;
  left: 1px;
  font-size: 24px;
  margin: 3px;
}

.thumbnail-contains-pinned-shadow {
  color: black;
  position: absolute;
  bottom: 53px;
  left: 1px;
  font-size: 24px;
  margin: 3px;
}

.thumbnail-card:hover {
  //color: $brand-yellow-2 !important; // need to force color, because specialized button styles have higher priority
  border-color: $brand-yellow-2 !important;
  transition: all 0.2s ease-in-out;
}

.thumbnail-image-container {
  width: -webkit-fill-available;
  height: -webkit-fill-available;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.thumbnail-selected {
  border-color: $brand-yellow-1 !important;
}

.thumbnail-complete {
  border-color: $brand-green;
}

.thumbnail-preloaded {
  border-color: $brand-blue;
}

.thumbnail-transparent {
  opacity: 0.5;
}
