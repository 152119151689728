@import '../../../../src/scss/variables.scss';

.chip {
  font-size: 14px;
  display: flex;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'MontSerrat';

  // Mimic React <Chip>.
  align-items: center;
  overflow: hidden;
  padding: 0.6em 1em;
  border-radius: 1000px; // Something large
  background-color: #fff;

  cursor: pointer;
  border: 0.05em solid #aaa;

  i {
    margin-right: 8px;
  }

  span {
    line-height: 1em;
  }

  &:hover {
    background-color: #f2f2f2;
  }
}
