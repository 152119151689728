.has-error {
  .placeholder-icon {
    color: $brand-red;
  }
}

.has-success {
  .placeholder-icon {
    color: $brand-green;
  }
}
