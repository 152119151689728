label.small-checkbox {
  cursor: pointer;
  line-height: 0;

  > input {
    display: none;
    
    &:checked ~ span {
      border-color: transparent;
      background: #0075ff;
    }
    &:checked ~ span::after {
      opacity: 1;
      transform: rotate(45deg);
    }
    &:checked:disabled ~ span {
      opacity: 0.3;
    }
    &:disabled ~ span {
      opacity: 0.3;
    }
  }
  
  span {
    display: inline-block;
    position: relative;
    width: 13px;
    height: 13px;
    background-color: #FFF;
    border-radius: 2px;
    border: 1px solid #555;
    transition: background 0.3s ease;
  
    &::after {
      content: '';
      position: absolute;
      left: 3px;
      width: 5px;
      height: 8px;
      opacity: 0;
      transform: rotate(45deg);
      border-right: 2px solid #fff;
      border-bottom: 2px solid #fff;
      transition: all 0.1s ease;
    }
  }
}