@import '../../../../src/scss/variables.scss';
$delete-confirmation-dialog-box-shadow-color: #00000060;

.delete-confirmation-dialog {
  position: fixed;
  z-index: 2147483647;
  overflow: hidden;
  box-shadow: 2px 2px 8px 0 $delete-confirmation-dialog-box-shadow-color;
  font-size: 14px;

  // Reset cursor (might be inherited depending on where markup of this dialog is rendered).
  cursor: default;

  /* Content */
  > div {
    margin: 25px;
    max-width: 350px;

    .hidden {
      display: none !important;
    }

    h1 {
      margin: 0;
      padding: 0;
      font-weight: bold;
      font-size: 1.2em;
      margin-bottom: 15px;
    }

    .error-info-box {
      display: none;
      margin-top: 20px;
      padding: 10px;
      background-color: #f8f8f8;
      border-left: 5px solid $brand-red;
      font-size: 0.95em;
    }

    .confirm-box {
      margin-top: 20px;
      padding: 10px;
      background-color: #f8f8f8;
      border-left: 5px solid #fac801;
      font-size: 0.95em;

      span.confirm-text {
        font-family: monospace;
        font-weight: bold;
      }

      input {
        margin-top: 10px;
        padding: 5px;
        width: 100%;
        font-size: 0.95em;
        border: 1px solid #ccc;
      }
    }

    .precheck {
      display: flex;

      span {
        flex: 1;
      }

      i.fas {
        width: fit-content;
      }
    }
  }

  /* Buttons */
  footer {
    display: flex;
    justify-content: flex-end;
    background-color: #eee;
    padding: 12px;

    button {
      font-size: 0.9em;
      padding: 7px 25px;
      border: none;
      border-bottom: 2px solid transparent;

      &:active {
        border-top: 2px solid transparent;
        border-bottom: none;
      }
    }

    button.cancel {
      margin-right: 5px;
      &:hover {
        background-color: #ddd;
      }
    }

    button.confirm {
      margin-right: 5px;

      &:not(.positive) {
        color: #fff;
        background-color: $brand-red;

        &[disabled] {
          background-color: lighten($brand-red, 20%);
        }

        &:hover:not([disabled]) {
          background-color: darken($brand-red, 10%);
        }
      }

      &.positive {
        color: #000;
        background-color: $brand-yellow-1;

        &[disabled] {
          background-color: lighten($brand-yellow-1, 20%);
        }

        &:hover:not([disabled]) {
          background-color: darken($brand-yellow-1, 10%);
        }
      }

      i {
        font-size: 1.2em;
        margin-right: 10px;
      }
    }
  }

  /* Background overlay */
  &:after {
    position: fixed;
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.2);
    z-index: -2;
  }

  /* Dialog background-color */
  &:before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: #fff;
    z-index: -1;
  }
}
