@import '../../../../src/scss/variables';

.accept-text-container {
  display: flex;

  & input[type='text'] {
    flex: 1 1 auto;
  }

  & a {
    flex: 0 0 32px;
    font-size: 32px;
    color: $brand-gray-4;

    &.accept {
      color: $brand-green;
      cursor: pointer;
    }

    &.reject {
      color: $brand-red;
      cursor: pointer;
    }
  }
}
