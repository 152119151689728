@import '../../../../src/scss/variables';

$slider-width: 2px;
$slider-circle-size: 30px;
$slider-circle-border-width: 2px;

.comparison-slider {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: #fff;
  overflow: hidden;
  z-index: 1; // New stacking context.

  // --slider-pos is initiated from the controller as well, in order to set the position
  // in pixels. If window is resized before moving the slider we need value in pixels.
  // The initial value of 50% is to prevent view from flickering (x=0 -> x=50%) before
  // the pixel value is set in the controller.
  --slider-pos: calc(50%);
  --slider-circle-size: 22px;

  // Slider circle
  > div:nth-of-type(1) {
    position: absolute;
    top: calc(50% - $slider-circle-size / 2 - $slider-circle-border-width);
    left: calc(var(--slider-pos) - $slider-circle-size / 2);
    width: $slider-circle-size;
    height: $slider-circle-size;

    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;

    background-color: #fffa;
    backdrop-filter: blur(5px);
    border-radius: 50%;
    border: $slider-circle-border-width solid #fffa;
    box-shadow: 0 0 4px 0 #000a;

    font-size: 16px;
    color: #000c;
    cursor: pointer;

    opacity: 0.7;
    z-index: 10;

    &.dragging {
      background-color: #fff;
    }

    &:hover,
    &.dragging {
      opacity: 1;
      transition:
        opacity 0.3s ease,
        gap 0.3s ease,
        color 0.3s ease;
      gap: 7px;

      .fas {
        color: #000;
      }
    }

    // Arrows inside circle.
    .fas {
      pointer-events: none;
      color: #000a;
    }

    // Top and bottom line.
    &::before,
    &::after {
      content: '';
      position: absolute;
      left: calc($slider-circle-size / 2 - $slider-width / 2 - $slider-circle-border-width);
      width: $slider-width;
      height: 100vh;

      display: block;
      background-color: #fff;

      cursor: ew-resize;
      z-index: 10;
    }

    // Top line.
    &::before {
      box-shadow: 0 -1px 3px 0 #000a;
      bottom: calc($slider-circle-size - $slider-circle-border-width);
    }

    // Bottom line.
    &::after {
      box-shadow: 0 1px 3px 0 #000a;
      top: calc($slider-circle-size - $slider-circle-border-width);
    }
  }

  // Element wrapper for the two elements to compare.
  > div:nth-of-type(2) > * {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    z-index: 1;

    // Clips the last child (that is on top).
    &:last-child {
      clip-path: polygon(0 0, var(--slider-pos) 0, var(--slider-pos) 100%, 0 100%);
    }
  }
}
