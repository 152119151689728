.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.flex-row {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;

  &.between {
    justify-content: space-between;
  }

  &.stretch {
    justify-content: stretch;
  }

  &.evenly {
    justify-content: space-evenly;
  }

  &.start {
    justify-content: flex-start;
  }

  // 'cross' relates to the cross axis of the current direction.
  &.cross-start {
    align-content: flex-start;
  }

  &.cross-center {
    align-items: center;
  }
}

.flex {
  &-column {
    display: flex;
    flex-direction: column;
  }

  &-wrap {
    flex-wrap: wrap;
  }

  &-space-between {
    justify-content: space-between;
  }
}

.flex-break {
  flex-basis: 100%;
  height: 0;
}

@media (max-width: 500px) {
  [class*='flex-sm'] {
    padding-left: 5px;
    padding-right: 5px;
  }

  .flex-sm-12 {
    flex: 0 0 100%;
  }
  .flex-sm-11 {
    flex: 0 0 91.6%;
  }
  .flex-sm-10 {
    flex: 0 0 83.3%;
  }
  .flex-sm-9 {
    flex: 0 0 75%;
  }
  .flex-sm-8 {
    flex: 0 0 66.6%;
  }
  .flex-sm-7 {
    flex: 0 0 58.3%;
  }
  .flex-sm-6 {
    flex: 0 0 50%;
  }
  .flex-sm-5 {
    flex: 0 0 41.6%;
  }
  .flex-sm-4 {
    flex: 0 0 33.3%;
  }
  .flex-sm-3 {
    flex: 0 0 25%;
  }
  .flex-sm-2 {
    flex: 0 0 16.6%;
  }
  .flex-sm-1 {
    flex: 0 0 8.3%;
  }
  .flex-sm-hide {
    display: none;
  }
}

@media (min-width: 500px) and (max-width: 1024px) {
  [class*='flex-md'] {
    padding-left: 5px;
    padding-right: 5px;
  }

  .flex-md-12 {
    flex: 0 0 100%;
  }
  .flex-md-11 {
    flex: 0 0 91.6%;
  }
  .flex-md-10 {
    flex: 0 0 83.3%;
  }
  .flex-md-9 {
    flex: 0 0 75%;
  }
  .flex-md-8 {
    flex: 0 0 66.6%;
  }
  .flex-md-7 {
    flex: 0 0 58.3%;
  }
  .flex-md-6 {
    flex: 0 0 50%;
  }
  .flex-md-5 {
    flex: 0 0 41.6%;
  }
  .flex-md-4 {
    flex: 0 0 33.3%;
  }
  .flex-md-3 {
    flex: 0 0 25%;
  }
  .flex-md-2 {
    flex: 0 0 16.6%;
  }
  .flex-md-1 {
    flex: 0 0 8.3%;
  }
  .flex-md-hide {
    display: none;
  }
}

@media (min-width: 1024px) and (max-width: 1600px) {
  [class*='flex-lg'] {
    padding-left: 5px;
    padding-right: 5px;
  }

  .flex-lg-12 {
    flex: 0 0 100%;
  }
  .flex-lg-11 {
    flex: 0 0 91.6%;
  }
  .flex-lg-10 {
    flex: 0 0 83.3%;
  }
  .flex-lg-9 {
    flex: 0 0 75%;
  }
  .flex-lg-8 {
    flex: 0 0 66.6%;
  }
  .flex-lg-7 {
    flex: 0 0 58.3%;
  }
  .flex-lg-6 {
    flex: 0 0 50%;
  }
  .flex-lg-5 {
    flex: 0 0 41.6%;
  }
  .flex-lg-4 {
    flex: 0 0 33.3%;
  }
  .flex-lg-3 {
    flex: 0 0 25%;
  }
  .flex-lg-2 {
    flex: 0 0 16.6%;
  }
  .flex-lg-1 {
    flex: 0 0 8.3%;
  }
  .flex-lg-hide {
    display: none;
  }
}

@media (min-width: 1600px) {
  [class*='flex-xl'] {
    padding-left: 5px;
    padding-right: 5px;
  }

  .flex-xl-12 {
    flex: 0 0 100%;
  }
  .flex-xl-11 {
    flex: 0 0 91.6%;
  }
  .flex-xl-10 {
    flex: 0 0 83.3%;
  }
  .flex-xl-9 {
    flex: 0 0 75%;
  }
  .flex-xl-8 {
    flex: 0 0 66.6%;
  }
  .flex-xl-7 {
    flex: 0 0 58.3%;
  }
  .flex-xl-6 {
    flex: 0 0 50%;
  }
  .flex-xl-5 {
    flex: 0 0 41.6%;
  }
  .flex-xl-4 {
    flex: 0 0 33.3%;
  }
  .flex-xl-3 {
    flex: 0 0 25%;
  }
  .flex-xl-2 {
    flex: 0 0 16.6%;
  }
  .flex-xl-1 {
    flex: 0 0 8.3%;
  }
  .flex-xl-hide {
    display: none;
  }
}
