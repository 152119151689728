@import '../../../../src/scss/variables';

/*
  CSS for the component selectionList
*/

.selectlist-container {
  -webkit-transition: all 0.5s; /* Safari */
  transition: all 0.5s;

  .selectlist-item {
    font-size: 0.9rem;

    .col {
      padding-top: 0;
      padding-bottom: 0;
    }
  }
}

.selectlist-title {
  display: flex;
  flex-direction: row;
  align-items: center;

  h4 {
    margin-right: auto;
  }
}

.selectlist-search {
  display: flex;
  flex-direction: row;
  align-items: center;

  position: relative;

  input {
    padding: 5px $size-4;
    line-height: normal;
    height: auto;
    width: 250px;
  }

  .fas {
    position: absolute;
    text-align: center;
    line-height: $size-5;
    color: $brand-gray-4;
    width: $size-4;
  }

  .fa-search {
    left: 0;
  }

  .fa-xmark {
    right: 0;

    &:hover {
      color: $brand-gray-2;
    }
  }
}

.selectlist-header {
  font-weight: bold;
  border-bottom: 2px solid $brand-gray-4;

  a {
    color: $brand-gray-1;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}
.selectlist-compact {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

// Wrap .selected with its container, to style only elements in this component.
// todo: All css should be wrapped with .selectlist-container.
.selectlist-container {
  .selected {
    background-color: $brand-yellow-1 !important;
  }
}

.selectlist-item {
  border-bottom: 1px solid $brand-gray-4;
  padding: 0;
  cursor: pointer;

  .delete-icon {
    display: none;
  }
}

.selectlist-item:hover {
  background-color: $brand-yellow-2 !important;
  transition: all 0.2s ease-in-out;

  .delete-icon {
    display: unset;
    position: absolute;
    right: 0;
    padding: 10px;

    cursor: pointer;
    opacity: 0.5;

    &:hover {
      opacity: 1;
    }
  }
}

.selectlist-cell {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  display: flex;
}

/** Overrides the checkbox background-color,
 *  so that it does not forces a white background */
.item-checkbox {
  background: transparent !important;
  border: 0;
  cursor: pointer;
}

/** Meta classes */
.meta-icon {
  font-size: 32px !important;
  text-align: center;
  flex: 1;
}

.meta-string {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  line-height: 32px;
}

/** Pagination classes */
.pagination-container {
  text-align: center;
  margin-top: $size-2;
  white-space: nowrap;
}

.pagination-item {
  border: 2px solid $brand-gray-4;
  width: $size-5;
  height: $size-5;
  text-align: center;
  color: black;
  border-radius: 50%;
  font-size: 16px;
  line-height: 36px; // $size-5 - 2 * border-size
  cursor: pointer;
  box-sizing: border-box;
  margin-left: 4px;
  margin-right: 4px;
  display: inline-block;

  &:hover {
    background-color: $brand-gray-4;
  }
}

.pagination-selected {
  background-color: $brand-yellow-1;
  border-color: $brand-yellow-1;

  &:hover {
    background-color: white;
  }
}

.inner-button {
  margin: 0 !important;
}
