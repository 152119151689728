/**
 * Radio Button Inputs
 * --------------------------------------------------
 */

.item-radio {
  padding: 0;

  &:hover {
    cursor: pointer;
  }
}

.item-radio .item-content {
  /* give some room to the right for the checkmark icon */
  padding-right: calc(#{$item-padding} * 4);
}

.item-radio .radio-icon {
  /* checkmark icon will be hidden by default */
  position: absolute;
  top: 0;
  right: 0;
  z-index: $z-index-item-radio;
  visibility: hidden;
  padding: calc(#{$item-padding} - 2px);
  height: 100%;
  font-size: 24px;
}

.item-radio input {
  /* hide any radio button inputs elements (the ugly circles) */
  position: absolute;
  left: -9999px;

  &:checked + .radio-content .item-content {
    /* style the item content when its checked */
    background: #f7f7f7;
  }

  &:checked + .radio-content .radio-icon {
    /* show the checkmark icon when its checked */
    visibility: visible;
  }
}
