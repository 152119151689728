#shared-view-canvas {
  .message-box {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    margin-top: 100px;

    h1 {
      color: #666;
    }

    section {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 40px;

      i {
        font-size: 60px;
      }

      span {
        width: 400px;
        margin-left: 30px;
        line-height: 1.3em;
      }
    }

    &.non-existing i {
      color: #888;
    }

    &.error i {
      color: #a00;
    }
  }
}
