@import '../../../../../src/scss/variables';

.process-data-dialog {
  .popeye-wrapper {
    max-width: unset;
    width: 650px;

    .process-data-tooltip {
      display: flex;
      color: $brand-blue;
      gap: 10px;
      align-items: baseline;

      i {
        font-size: 1.2em;
        position: relative;

        tooltip {
          position: absolute;
          left: 50%;

          ul {
            padding-left: 10px;
            list-style: disc;
          }
        }
      }
    }
    .process-data-options {
      position: relative;
      margin-top: 20px;
      height: 40px;

      label {
        &:hover {
          color: $brand-yellow-1;
        }

        white-space: nowrap;
        color: $brand-blue;
        cursor: pointer;
        margin-top: 10px;
      }

      div {
        display: flex;
        float: right;
        gap: 10px;

        button {
          width: 175px;
          margin: 0;
          white-space: nowrap;
        }
      }
    }
  }
}
