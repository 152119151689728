#access-token-redirect {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;

  .fas {
    font-size: 1.5em;
    color: crimson;
  }

  span {
    font-size: 1.2em;
  }
}
