#notsupported {
  display: grid;
  place-content: center;
  height: 100vh;
  text-align: center;

  h1 {
    font-size: 18pt;
    margin-bottom: 20px;
  }

  span {
    line-height: 1.2em;
  }

  .supported-browsers {
    display: flex;
    flex-direction: row;
    margin-top: 20px;
    justify-content: center;

    a {
      margin: 0 5px;
    }

    img {
      height: 40px;
      opacity: 0.6;

      &:hover {
        opacity: 1;
        cursor: pointer;
      }
    }
  }
}
