@import '../../../../src/scss/variables';

.sky-progress-bar {
  --progress: 0%;

  position: relative;
  background-color: $brand-yellow-4;
  z-index: 0;
  width: clamp(300px, 30%, 400px);
  padding: 15px;
  box-shadow:
    0 1px 3px rgba(0, 0, 0, 0.12),
    0 1px 2px rgba(0, 0, 0, 0.24);

  // Progressbar
  &:not(.error)::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: var(--progress);
    height: 100%;
    background-color: $brand-yellow-1;
    z-index: -1;
  }

  // Error style.
  &.error {
    background-color: #fff;
    border-left: 5px solid firebrick;
  }

  // Retry icon.
  i {
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
  }

  span {
    display: block;

    &.progress-bar-header {
      font-size: 1.1em;
      font-weight: 200;
      margin-bottom: 7px;
    }

    &.progress-bar-text {
      font-size: 0.9em;
    }

    &.progress-bar-percent {
      display: block;
      margin: 12px 0 0 0;
      font-size: 1.5em;
    }

    &.progress-bar-percent-text {
      display: block;
      margin-top: 12px;
      font-size: 0.8em;
      color: #000a;
    }
  }
}
