@import '../../../../src/scss/variables';

.file-uploader-container {
  .choose-file-button {
    display: block;
  }

  .selected-file-name {
    margin-top: auto;
    margin-bottom: auto;
  }

  .clear-file-button {
    text-align: right;
  }

  .file-uploader-grid-header {
    font-weight: bold;
    margin-top: $default-indent;
    border-bottom: 2px solid $brand-gray-4;
    padding-top: calc($grid-padding-width / 2);
  }

  .file-uploader-grid-row {
    // Remove default grid behavior. Rows should not be packed dense.
    margin-top: 0;
    padding-top: 5px;

    > div {
      margin-top: auto;
      margin-bottom: auto;
    }
  }

  .item-progress-box {
    padding: 0;
    height: 20px;
    border: 1px solid $brand-gray-3;
    border-radius: 5px;
    background-color: $brand-yellow-3;
  }

  .item-progress {
    background-color: $brand-yellow-1;
    border-radius: 5px;
    height: 100%;
    width: 0;
  }

  .drag-over {
    background-color: $brand-yellow-2;
    border: 2px dashed $brand-gray-2 !important;
    border-radius: 5px;
  }
}
