@import '../../../../src/scss/variables';

.create-account-container {
  width: 100%;

  & > div {
    widows: 100%;
    display: flex;

    & > * {
      align-self: center;
      margin-left: 2px;
      margin-right: 2px;
      flex: 0 0 auto;
    }

    & > searchable-dropdown {
      flex: 0 0 192px;
    }

    & > input {
      flex: 4 0 auto;
      border: 1px solid $brand-gray-2;
      border-radius: 4px;
      padding: 16px;
      font-family: $font-family-header;
    }
  }

  & .button {
    flex: 1 1 auto;
  }
}
