#canvas-asset-info-box {
  position: absolute;
  width: 220px;
  background-color: rgba(64, 64, 64, 0.9);
  padding: 8px;
  color: #fff;
  border-radius: 4px;
  box-shadow:
    0 3px 6px rgba(0, 0, 0, 0.16),
    0 3px 6px rgba(0, 0, 0, 0.23);
  font-family: 'Montserrat', sans-serif;
  font-size: 10pt;
  pointer-events: none;

  > div {
    display: grid;
    grid-auto-columns: 1fr;
    grid-column-gap: 50px;
    grid-row-gap: 10px;

    &.comment {
      grid-template-areas:
        'coordinate-system  coordinate-system'
        'northing           easting'
        'mas                mas';
    }

    &.line {
      grid-template-areas:
        'length             plane-length'
        'coordinate-system  coordinate-system'
        'northing           easting'
        'mas                mas';

      .coordinate-system {
        display: block;
        margin-top: 5px;
        border-top: 1px solid #fffa;
        padding-top: 15px;
      }
    }

    &.area {
      grid-template-areas:
        'area               plane-area'
        'circumference      plane-length'
        'coordinate-system  coordinate-system'
        'northing           easting'
        'mas                mas';

      .coordinate-system {
        display: block;
        margin-top: 5px;
        border-top: 1px solid #fffa;
        padding-top: 15px;
      }
    }
  }

  p {
    margin: 0;

    label {
      display: block;
      text-align: left;
      color: #fffa;
      font-size: 0.9em;
      margin-bottom: 2px;
    }
  }

  .area {
    grid-area: area;
  }

  .plane-area {
    grid-area: plane-area;
  }

  .circumference {
    grid-area: circumference;
  }

  .plane-length {
    grid-area: plane-length;
  }

  .coordinate-system {
    grid-area: coordinate-system;
  }

  .northing {
    grid-area: northing;
  }

  .easting {
    grid-area: easting;
  }

  .mas {
    grid-area: mas;

    margin-top: 5px;
    border-top: 1px solid #fffa;
    padding-top: 15px;
  }
}
