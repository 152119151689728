@import '../../../../src/scss/variables';

.transfer-status-container {
  display: flex;
  border-radius: 2px;
  padding: 8px;
  margin: auto;
  flex-flow: row wrap;

  & > a {
    flex: 0 0 32px;
    font-size: 32px;
  }

  & > h1,
  h2,
  h3,
  h4,
  h5 {
    flex: 1 1 auto;
    font-weight: bold;
  }

  & > div {
    padding: 8px;
    font-family: $font-family-header;
    order: 1;
    text-align: left;
    line-height: 120%;
  }

  & + button {
    width: 400px;
    margin: auto;
  }
}
