
tab-container {
  width: 100%;
  display: flex;
  padding-top: 8px;

  & tab {
    flex: 1 1 auto;
    font-family: $font-family-header;

    padding: 4px;
    border: 1px solid gray;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    box-shadow: 1px -1px #888888;

    &:hover {
      background-color: $brand-yellow-3;
    }

    &.active {
      border-bottom: none;
    }
  }
}
