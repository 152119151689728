#fullScreenLoader {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  display: grid;
  place-content: center;

  background-color: #0005;
  color: #fff;

  z-index: 2147483647;

  .fas {
    font-size: 30pt;
  }
}
