.info-box {
  display: inline-block;
  margin: 20px 0;
  padding: 15px;
  background-color: #f8f8f8;
  line-height: 1.4em;

  &.no-margin-top {
    margin-top: 0;
  }
  &.no-margin-bottom {
    margin-bottom: 0;
  }

  > span {
    display: block;
    font-weight: bold;
    margin-bottom: 5px;
  }

  // Extra space, known bug in Angular JS.
  // https://github.com/angular/angular.js/issues/8132
  &[data-type='yellow'],
  &[data-type='yellow '] {
    border-left: 5px solid #fac801;
  }

  &[data-type='red'],
  &[data-type='red '] {
    border-left: 5px solid firebrick;
  }
}
