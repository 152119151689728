@charset "UTF-8";


.skyview-spinner-container {
  position: absolute;

  width:56px;
  height:56px;
  right: 20px;
  top:20px;
  left: 50%;
  transform: translateX(-50%); 

  background-color: $brand-yellow-1;
  color: black;

  border-radius: 50%;


  .skyview-spinner {
    top: 13px;
    left: 13px;

    position: relative;

    stroke: #222!important;
    .stop1{
      stop-color:#222;
    }
    .stop2{
      stop-color:#222;
    }

  }
}

