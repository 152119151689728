:root[data-theme='moba'] {
  //
  // FULL PAGE BOX COMPONENT
  //
  --full-page-box-background: url('/static/themes/moba/images/background.png') bottom left fixed
    no-repeat rgba(0, 0, 0, 0.8);
  --full-page-box-background-size: none;
  --full-page-box-logotype: url('/static/themes/moba/images/logotype.png');

  //
  // CAD VIEWER
  //
  // Header
  --top-menu-background-color: #fc0;
  --top-menu-logotype: url('/static/themes/moba/images/logotype.png');
  --top-menu-text-color: #000;
}
