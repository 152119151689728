@import '../../../../src/scss/variables.scss';

$menu-resize-icon-width: 20px;
$menu-resize-icon-height: 160px;
$menu-resize-icon-top: calc(50% - 80px);
$menu-resize-icon-padding: 0px;

// Default theming.
:root {
  --collapsable-menu-container-resize-button-background-color: #5e6a71;
  --collapsable-menu-container-resize-button-background-color-hover: #{darken(#5e6a71, 10%)};
  --collapsable-menu-container-resize-button-color: #fff;
}

#collapsable-menu-container {
  --width: var(--collapsable-menu-container-expanded-width);

  position: relative;
  width: var(--width);
  height: 100%;
  transition: width $asset-menu-transition-delay $asset-menu-transition-timing-function;
  pointer-events: all;
  z-index: 2;

  > .overflow-wrapper {
    height: 100%;
    overflow: hidden;

    > .content {
      width: var(--collapsable-menu-container-expanded-width);
      height: 100%;
    }
  }

  &.collapsed {
    --width: var(--collapsable-menu-container-collapsed-width);
  }

  //
  // Collapse/expand button
  //
  .resize-button {
    background-color: var(--collapsable-menu-container-resize-button-background-color);
    position: absolute;
    width: $menu-resize-icon-width;
    height: $menu-resize-icon-height;
    top: $menu-resize-icon-top;
    right: calc(-#{$menu-resize-icon-width} - #{$menu-resize-icon-padding});
    display: inline-block;
    cursor: pointer;
    z-index: 200;

    &:hover {
      background-color: var(--collapsable-menu-container-resize-button-background-color-hover);
      transition: background-color 0.3s ease;
    }

    &::before,
    &::after {
      content: '';
      position: absolute;
      width: 15%;
      height: 12px;
      background-color: var(--collapsable-menu-container-resize-button-color);
      transition: all $asset-menu-transition-delay ease;
      border-radius: 1px;
    }

    &::before {
      top: calc(50% - 2px);
      left: 35%;
      transform: rotate(130deg);
    }

    &::after {
      bottom: calc(50% - 3px);
      left: 35%;
      transform: rotate(-130deg);
    }

    &.collapsed::before {
      left: 40%;
      transform: rotate(50deg);
    }

    &.collapsed::after {
      left: 40%;
      transform: rotate(-50deg);
    }
  }
}
