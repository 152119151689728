@import '../../../../src/scss/variables.scss';

.gcp-correction-job-request-container {
  display: flex;

  padding: 0 16px;
  margin: 4px;

  > * {
    margin: auto 8px;
  }

  > .request-info,
  > .error-text,
  label {
    font-family: $font-family-header;
    font-size: 12px;
    font-weight: bold;
  }

  input[type='checkbox'] {
    margin: 9px 4px;
  }

  input[type='text'] {
    background-color: #fafafa;
    border: 1px solid #ddd;
  }

  > .error-text {
    max-width: 256px;
  }

  > .resend-button {
    cursor: pointer;
  }

  &:not(.request-sent) > .request-info,
  &:not(.request-sent):not(.error) > .resend-button,
  &:not(.loading) > .fa-spinner {
    display: none;
  }

  &.request-sent > button,
  &.loading > button,
  &.loading > .request-info,
  &.loading > .resend-button,
  &.loading > .urgent-container,
  &.loading > .note-container,
  &:not(.error) > .error-text {
    display: none;
  }

  &.error {
    background-color: #ffe0e0;
    border: 1px solid #f66;

    > button,
    > .request-info,
    > .urgent-container,
    > .note-container {
      display: none;
    }
  }
}
