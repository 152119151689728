/**
 * This file includes declaration of all variables and mixins used through the
 * application.
 *
 * Ensure that it does not contain any style definitions. It should be
 * possible to import this file into any another SCSS file to access variables
 * or mixins defined here without causing any side-effects.
 */

// Paths for font files, relative to the built CSS in www/static
$dxf-font-path: '../static/fonts';

// Custom fonts
@import url('https://fonts.googleapis.com/css?family=Montserrat:100,200,300,400,500,600,700,800,900&subset=latin-ext');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@100..700&display=swap');
//@import url('../static/fonts/dxf_fonts/isocp.ttf');
//@import url('../static/fonts/dxf_fonts/isocpeur.ttf');

// Brand colors
$brand-yellow-1: #fed530;
$brand-yellow-2: #feed99;
$brand-yellow-3: #fff1ae;
$brand-yellow-4: #fff8d5;

$brand-red: #db4d69;
$brand-blue: #00a9e0;
$brand-green: #00ad83;

$brand-gray-1: #37424a;
$brand-gray-2: #5e6a71;
$brand-gray-3: #a5acaf;
$brand-gray-4: #d1d4d3;
$brand-gray-5: #eee;

// Brand fonts
$font-family-header: 'Montserrat', sans-serif;

// Standard sizes
$size-1: 8px;
$size-2: 16px;
$size-3: 24px;
$size-4: 32px;
$size-5: 40px;
$size-6: 48px;
$size-7: 56px;
$size-8: 64px;

$default-indent: $size-2;

// Ionic customizations
$stable: $brand-gray-4;
$positive: $brand-yellow-1;
$calm: $brand-blue;
$balanced: $brand-green;
$assertive: $brand-red;
$dark: $brand-gray-2;

$button-positive-text: #000;

$item-default-active-bg: $brand-gray-4;

$bar-height: $size-6;

$font-size-base: 16px;
$font-size-large: 18px;
$font-size-small: 14px;
$line-height-base: 1;

$button-border-radius: 2px;
$button-border-width: 0;
$button-height: $size-5 - 1px; // -1px is to workaround code in Ionic CCS (see line-height in _button.scss)
$button-block-margin: $size-2;

$content-padding: $size-2;

$grid-padding-width: $size-2;

$input-bg-disabled: lighten($brand-gray-4, 15);

/**
 *  z-index grouping to easily see the layout
 */
$backdrop-z: 100000;

$searchable-dropdown-z: 100;

// Asset menu
$asset-menu-category-header-height: 35px;
$asset-menu-transition-delay: 0.25s;
$asset-menu-transition-timing-function: cubic-bezier(0.57, 0.06, 0.58, 0.88);