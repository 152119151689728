#admin-set-gcp {
  background-color: #fff;
  position: relative;

  .header {
    display: flex;

    // This is a replica from
    // .skymap-body > h1:first-of-kind
    // Because it is nested into .header to handle positioning with flex,
    // it loses the styling below.
    // TODO: Fix structure for integrating elements in header.
    > h1 {
      margin: 0 0 20px 0;
      padding: 0;
      text-transform: uppercase;
      letter-spacing: 0.1em;
      font-size: 14pt;
      font-weight: bold;
      color: #333;
    }
  }

  .enter-fullscreen {
    position: absolute;
    top: 20px;
    right: 25px;
    padding: 5px;
    cursor: pointer;
  }

  .exit-fullscreen {
    position: absolute;
    top: 7px;
    left: 50%;
    transform: translateX(-50%);
    padding: 5px;
    font-family: 'Montserrat';
    cursor: pointer;
  }

  .enter-fullscreen,
  .exit-fullscreen {
    &:hover {
      background-color: #eee;
    }
  }

  // Fullscreen
  &:fullscreen {
    padding: 40px 10px 10px 10px;

    .enter-fullscreen,
    .header {
      display: none;
    }

    h1 {
      display: none;
    }

    div.gcp-main-window {
      height: calc(100vh - 40px - 10px);
    }
  }

  // Not fullscreen
  &:not(:fullscreen) {
    .exit-fullscreen {
      display: none;
    }

    div.gcp-main-window {
      height: calc(100vh - 265px);

      &.display-gcp-request-email {
        height: calc(100vh - 280px);
      }
    }
  }
}
