input.form-input {
  line-height: $size-5;
  height: $size-5;
  border: 1px solid $brand-gray-4;
  padding: 0 $size-1 * 1.5;
  width: 100%;
}

textarea.form-input {
  line-height: $size-3;
  border: 1px solid $brand-gray-4;
  padding: 0 $size-1 * 1.5;
  width: 100%;
  resize: none;
}

input.form-input-large {
  line-height: $size-5;
  height: $size-5;
}

input.form-input.valid {
  border-color: $brand-green;
  background-color: #e8f5e9;
}

input.form-input.invalid {
  border-color: $brand-red;
  background-color: lighten($brand-red, 40);
}

.form-group {
  display: block;
  margin-bottom: $default-indent;

  &:last-child {
    margin-bottom: 0;
  }
}

.form-group {
  .form-input,
  .form-checkbox {
    margin-top: $size-1;
    margin-bottom: $size-1 * 0.5;
  }
}
