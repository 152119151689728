@import '../../../../../src/scss/variables';

#geodata-upload {
  width: 1100px;
  height: auto;
  min-height: 500px;
  background-color: white;
  overflow-y: auto;

  &:not(.iframe) {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 30px;

    @media screen and (max-height: 900px) {
      top: 0;
      left: 0;
      width: 100%;
      transform: unset;
      height: 100vh;
    }

    @media screen and (max-width: 1100px) {
      top: 0;
      left: 0;
      width: 100%;
      transform: unset;
      height: 100vh;
    }
  }

  &.iframe {
    // 48px is height of title in iframe-mode.
    margin: calc(40px + 48px) 20px 20px 20px;
    margin-left: 50%;
    transform: translateX(-50%);
  }
}

.projection-settings-footer {
  display: flex;
  justify-content: space-between;
  margin-top: 3em;
}

.geodata-upload-align-settings {
  margin: 15px !important;
}

.geodata-upload-grid {
  margin: 10px;

  display: grid;
  grid-template-rows: auto minmax(300px, 1fr) auto;
  grid-template-areas:
    'header'
    'upload-body'
    'footer';

  aside {
    position: absolute;
    right: 20px;
    top: 20px;
    color: #888;
  }

  &.first-page {
    display: block;
    grid-template-rows: 35px 20px 1fr 10px;
    grid-template-areas:
      'header-first'
      'title'
      'upload-body'
      'footer';

    .header-type {
      display: none;
    }

    footer {
      .previous,
      .next {
        display: none;
      }
    }
  }

  &:not(.first-page) {
    .header-first {
      display: none;
    }
  }

  .header-first {
    grid-area: header-first;
  }

  .title {
    grid-area: title;
  }

  h2 {
    grid-area: header;
  }

  .info-message {
    margin: 20px 0;
    padding: 15px;
    background-color: #f8f8f8;
    border-left: 5px solid #fac801;
    line-height: 1.2em;
  }

  .upload-body {
    .process-type {
      margin: 20px 0;
      display: flex;
      gap: 20px;

      .card {
        position: relative;
        display: flex;
        flex: 1;
        flex-direction: column;
        box-shadow:
          0 1px 3px rgba(0, 0, 0, 0.12),
          0 1px 2px rgba(0, 0, 0, 0.24);
        padding: 20px;
        font-family: 'Montserrat', sans-serif;
        font-size: 11pt;
        background-color: #fafafa;
        pointer-events: all;
        border-radius: 5px;

        &:hover {
          box-shadow:
            0 3px 6px rgba(0, 0, 0, 0.16),
            0 3px 6px rgba(0, 0, 0, 0.23);
          background-color: #f6f6f6;
        }

        img {
          width: 80%;
          align-self: center;
        }

        .description {
          text-align: center;
          line-height: 1.2em;
          height: 90px;
          font-size: 11pt;
        }

        h3 {
          text-align: center;
          font-size: 17pt;
          margin: 20px 0;
        }

        ul {
          margin: 20px auto;
          flex: 1;

          li {
            margin: 3px;
            font-size: 11pt;
          }

          li::before {
            content: '\f00c';
            font-family: 'FontAwesome';
            color: $brand-green;
            padding: 0px 6px;
          }
        }

        button {
          margin: auto;
          width: 200px;
        }
      }
    }

    .add-on {
      height: 350px;
      margin: 20px 0;
      overflow-y: auto;
    }

    .tif-zip {
      padding: 1em;
      margin: 1em;

      > .zip-upload-container {
        display: flex;
        flex-direction: column;
      }

      > .zip-file-container {
        display: grid;
        grid-template-columns: auto 1fr auto;
        grid-template-rows: 1fr;

        align-items: center;

        > i,
        > button {
          padding: 0.5em;
        }
      }
    }

    .images {
      display: flex;
      flex-direction: column;
      height: 100%;

      .add-photos-button {
        height: 60px;
      }
      .add-photos-field {
        flex: 1;

        .add-photos-field-item {
          float: right;
          margin-top: -14px;
        }
      }
      .number-of-photos {
        height: 40px;
        white-space: nowrap;
        display: flex;
      }
    }

    .gcp {
      display: flex;
      flex-direction: column;
      height: 100%;

      .description {
        height: 10px;
      }

      .upload-button {
        height: 60px;
      }

      .list {
        flex: 1;
        overflow-y: auto;
      }

      label {
        margin: 15px 0 0 0;

        span {
          user-select: none;
        }

        i {
          cursor: pointer;
          margin: 4px 10px 0 4px;
          padding: 0 2px 0 2px;

          &:hover {
            color: $brand-gray-2;
            transition: background-color 0.2s ease-in-out;
          }
        }
      }
    }

    .options {
      min-height: 400px;

      input[type='text'] {
        &.description {
          flex: 1;
        }
        &.gsd {
          width: 70px;
          margin-right: 10px;
        }
      }

      h4 {
        margin: 40px 0 20px 0;
      }

      .geodata-notification {
        margin-top: 10px;
      }

      .toggle {
        margin-top: 2px;
        cursor: pointer;
      }
    }

    .summary {
      min-height: 400px;
    }

    .info-row {
      margin: 15px 0 20px 0;

      > h5 {
        font-weight: bold;
      }

      > div {
        display: flex;
        align-items: center;
      }

      select {
        margin-left: 10px;
        width: 300px;
      }

      input[type='text'] {
        border-bottom: 1px solid $brand-gray-4;
      }
      input[type='number'] {
        margin-left: 10px;
        width: 150px;
        border-bottom: 1px solid $brand-gray-4;
      }

      p {
        margin: 0;
      }

      .fa-circle-info {
        position: relative;
        font-size: 1.3em;
        color: $brand-blue;
        margin-left: 10px;
      }
    }
  }

  footer {
    display: flex;
    justify-content: flex-end;
    position: relative;
    gap: 8px;
    margin-top: 20px;

    a {
      color: $brand-blue;
      position: absolute;
      left: 0;
    }

    button {
      width: 200px;
    }
  }

  .file-upload-text {
    border: 1px solid $brand-gray-4 !important;
    background-color: white;
    height: 35px;
    margin: 0 15px 0 0;
    padding: 7px;
    width: 350px;
  }

  .geodata-notification {
    display: flex;
    align-items: center;
    margin-top: -10px;
  }

  .geodata-list-error-icon {
    color: $brand-red;
    font-size: 18px;
    padding: 0px 6px;
  }
}

.geodata-info {
  font-family: Montserrat;
  text-align: center;
  align-self: center;

  margin: 30px 0 30px 0;
  display: flex;
  flex-direction: column;
  gap: 10px;

  h2 {
    height: 40px;
  }

  .geodata-info-body {
    height: 400px;
    overflow-y: auto;
    overflow-x: hidden;
  }

  footer {
    height: 35px;
    align-self: center;
  }
}

geodata-list .file-picker {
  opacity: 0;
}

/*
 * Progress bar
 */
.geodata-info-uploading {
  button {
    padding: 0 50px !important;
    margin-top: 40px;
  }

  .progress {
    position: relative;
    height: 50px;
    width: 50%;
    left: 50%;
    transform: translateX(-50%);

    progress {
      -webkit-appearance: none;
      border: 1px solid $brand-yellow-1;
      height: 100%;
      width: 100%;
      margin: 0;

      &::-webkit-progress-bar {
        background-color: lighten($brand-yellow-1, 20);
      }

      &::-webkit-progress-value {
        background-color: $brand-yellow-1;
      }
    }

    > div {
      position: absolute;
      display: block;
      color: #000;
      font-size: 1.1em;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }

    &.error {
      span {
        color: #fff;
      }

      progress {
        border: 1px solid $brand-red;

        &::-webkit-progress-bar {
          background-color: lighten($brand-red, 20);
        }

        &::-webkit-progress-value {
          background-color: $brand-red !important;
        }
      }
    }
  }
}
#fileListDiv {
  position: relative;
  height: 400px;
  overflow-y: auto;
  flex: 1 1 auto;
  padding: 8px;

  .drop-target {
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    i {
      font-size: 7em;
      opacity: 0.02;
      position: absolute;
      display: block;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      pointer-events: none;
    }

    span {
      position: absolute;
      color: #555;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      white-space: nowrap;
      pointer-events: none;
    }
  }

  &.dragged-over {
    .list {
      display: none;
    }

    .drop-target i {
      opacity: 0.1;

      -webkit-animation: action 0.5s infinite alternate;
      animation: action 0.5s infinite alternate;
    }
  }

  & .list {
    max-height: unset;
  }
}

@-webkit-keyframes action {
  0% {
    transform: translate(-50%, -40%);
  }
  100% {
    transform: translate(-50%, -60%);
  }
}
@keyframes action {
  0% {
    transform: translate(-50%, -40%);
  }
  100% {
    transform: translate(-50%, -60%);
  }
}

.geodata-list-container {
  background-color: #eee;
  padding: 8px;

  .hidden {
    display: none;
  }

  & .list {
    max-height: 770px;
    overflow-x: hidden;
    overflow-y: auto;

    padding: 4px;
  }

  & .list-item {
    padding: 8px;
    margin-bottom: 8px;

    background-color: white;

    -webkit-box-shadow: 1px 3px 5px 0px rgba(148, 148, 148, 1);
    -moz-box-shadow: 1px 3px 5px 0px rgba(148, 148, 148, 1);
    box-shadow: 1px 3px 5px 0px rgba(148, 148, 148, 1);

    & .title {
      flex-wrap: nowrap;
    }

    & .details {
      width: 100%;
      font-size: 16px;
      padding-bottom: 10px;
      & * {
        font-family: $font-family-header;
      }

      .details-header {
        padding-bottom: 4px;

        &[data-expanded='true']::after {
          content: '▼';
          margin-left: 5px;
          color: $brand-gray-1;
        }

        &[data-expanded='false']::after {
          content: '►';
          margin-left: 5px;
          color: $brand-gray-1;
        }
      }

      & .details-item {
        padding-left: 4px;
        line-height: 1.25em;
        b {
          color: $brand-gray-1;
        }
      }
    }

    & i {
      color: $brand-gray-2;
      font-weight: bold;
      cursor: pointer;

      &.error {
        color: $brand-red;
      }

      &:hover {
        text-decoration: underline;
      }
    }

    & .flex-row > * {
      margin: 4px 8px;
    }
  }
}
