@import '../../../../../src/scss/variables';

.gcp-loader-container {
  position: relative;
  background: $brand-gray-4;
  border-radius: 4px;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.gcp-loader-header {
  flex: 0 0 auto;
}

.gcp-loader-body-container {
  overflow-x: auto;
}

.gcp-loader-body {
  flex: 1 1 auto;
  overflow: auto;
  width: 615px;
}

.gcp-loader-body-small {
  flex: 1 1 auto;
  overflow: auto;
  max-width: 100px;
}

.gcp-loader-expand-button {
  top: 10px;
  right: -52px;
  position: absolute;
  width: 35px;
  height: 35px;
  color: $brand-gray-4;
  background-color: $brand-gray-2;
  border-radius: 50%;
  z-index: 2;

  &::before {
    font-size: 1.2em;
    color: $brand-gray-3;
  }
  &.open::before {
    content: '\f053';
    margin-left: -5px;
  }
  &.closed::before {
    content: '\f054';
    margin-left: -1px;
  }
}

.gcp-loader-expand-button:hover {
  color: $brand-gray-2 !important;
  background-color: $brand-gray-4;
  cursor: pointer;
  transition-duration: 0.2s;
  border: 1px solid #888;
}

.gcp-loader-contents {
  input,
  i {
    margin: 4px 10px 0 4px;
  }

  i {
    cursor: pointer;
    padding: 0 2px 0 2px;

    &:hover {
      color: $brand-gray-2;
      transition: background-color 0.2s ease-in-out;
    }
  }

  span {
    margin-right: 2em;
  }

  margin: 10px;
}

input[type='file'] {
  display: none;
}
