.full-page-box {
  --width: 400px;

  position: relative;
  z-index: 3; // Render above other (todo: check why 1 isn't valid).
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  &:not(.ignore-background-image)::after {
    /* IMAGES */
    background: var(--full-page-box-background);
    background-size: var(--full-page-box-background-size, cover);
  }

  // Background
  &::after {
    content: '';
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    z-index: -1;
  }

  &.gray-background::after {
    filter: grayscale(100%);
  }
  &.blurred-background::after {
    filter: blur(3px);
  }
  &.gray-background.blurred-background::after {
    filter: grayscale(100%) blur(3px);
  }

  > section {
    padding: 20px;
    width: var(--width);
    background-color: #fff;
    border-radius: 6px;
    box-shadow: 5px 5px 15px 0 rgba(#000, 0.5);

    .logotype {
      width: 100%;
      height: 50px;
      margin-bottom: 30px;
      background: var(--full-page-box-logotype) center no-repeat;
      background-size: contain;
    }
  }
  &.hide-logotype > section .logotype {
    display: none;
  }
}
