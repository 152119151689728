.gps-marker-container {
  position: absolute;
  width: 32px;
  height: 32px;
  background: transparent;
  pointer-events: none;

  > .gps-marker {
    position: absolute;
    font-size: 68px;
    left: -50%;
    top: -186%;
    color: #fed530;
    pointer-events: none;
  }
}

#webGlNotSupported {
  span {
    display: block;
    line-height: 1.3em;
    font-size: 10pt;
    margin-bottom: 20px;
  }
}

/**
  It appears that the resize of canvas in SkyView increases the
  container size if it is not specifically set.
  This ensures that the canvas covers the whole page and remain un-resized.

  As discussion regarding layout change has been invoked, not implementing
  a full scale implementation. Only implementing a fix at hand.

  Note that this will not fix shared view nor the CAD-viewer.
*/
#viewer {
  width: 100%;
  height: 100%;

  // This should probably be handled in a better layout strategy.
  // Without it, 100% size generates a small scroll effect.
  position: relative;
  overflow: hidden;
}
