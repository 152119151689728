@import '../../../../src/scss/variables';

// Make sure this line up with item size in directive JS-file.
$itemSize: 30px;

.date-time-picker-popup {
  --week-count: 5;
  &[data-six-week-calendar='true'] {
    --week-count: 6;
  }

  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000a;
  z-index: 1000;

  // Revert changes for this class for this context.
  .item {
    all: revert;
  }

  // Time included?
  &[data-display-time='true'] .date-time-picker-popup-content {
    grid-template-areas:
      'header header'
      'date-header time-header'
      'date-content time-content'
      'footer footer';
  }

  // Time excluded?
  &[data-display-time='false'] .date-time-picker-popup-content {
    grid-template-areas:
      'header'
      'date-header'
      'date-content'
      'footer';
  }

  // Centers popup.
  // Take into account the height of the extra row when calendar
  // contains six weeks, and make dialog only expand downwards.
  .date-time-picker-popup-content {
    transform: translate(-50%, -50%);
  }
  &[data-six-week-calendar='true'] .date-time-picker-popup-content {
    transform: translate(-50%, calc(-50% + (#{$itemSize} / 2)));
  }

  // Popup content.
  .date-time-picker-popup-content {
    --gap: 15px;

    position: absolute;
    top: 50%;
    left: 50%;

    display: grid;
    overflow: hidden;
    font-family: 'Montserrat', sans-serif;
    font-size: 9pt;
    box-shadow:
      0 3px 6px rgba(0, 0, 0, 0.16),
      0 3px 6px rgba(0, 0, 0, 0.23);
    background-color: #fff;
    border-radius: 5px;
    z-index: 10000;

    header {
      grid-area: header;

      display: flex;
      justify-content: center;

      text-transform: uppercase;
      font-size: 1.2em;
      background-color: $brand-gray-5;
      padding: 20px;

      pointer-events: none;
    }

    footer {
      grid-area: footer;

      display: flex;
      justify-content: space-between;
      margin: var(--gap);

      button {
        padding: 7px 10px;
        font-family: 'Montserrat', sans-serif;
        line-height: normal;
        font-size: 10pt;
        width: 65px;
      }
    }

    .date-header {
      grid-area: date-header;

      display: flex;
      justify-content: space-between;
      align-items: center;

      // Remove some pixels below to compensate for table "top padding".
      margin: var(--gap) var(--gap) calc(var(--gap) - 5px) var(--gap);

      .fas {
        font-size: 10pt;
        cursor: pointer;
        padding: 5px;
      }

      .calendar-title {
        font-weight: 600;
        font-size: 1.1em;
        pointer-events: none;
      }
    }

    .date-content {
      grid-area: date-content;
      margin: 0 calc(var(--gap) / 2) 0 var(--gap);

      table {
        border-spacing: 0px;
        border-style: solid;
        border-collapse: separate;
      }

      thead {
        pointer-events: none;
      }

      th.header-weekday {
        font-weight: 600;
      }

      th,
      td {
        width: #{$itemSize};
        height: #{$itemSize};
        text-align: center;
        vertical-align: middle;
        font-weight: 400;
      }

      .weekend {
        font-weight: 600;
      }

      .week-number {
        background-color: lighten($brand-gray-5, 3%);
        pointer-events: none;
      }
    }

    .time-header {
      grid-area: time-header;

      display: flex;
      justify-content: center;
      align-items: center;

      // Remove some pixels below to compensate for table "top padding".
      margin: var(--gap) var(--gap) calc(var(--gap) - 5px) var(--gap);

      font-weight: 600;
      font-size: 1.1em;
    }

    .time-content {
      grid-area: time-content;
      display: flex;
      margin: 0 var(--gap) 0 calc(var(--gap) / 2);

      ul {
        display: flex;
        flex-direction: column;
        overflow-y: scroll;
        margin: 0px 0 0 0;
        padding: 0;
        max-height: calc((var(--week-count) + 1) * #{$itemSize});
        list-style-type: none;

        li {
          display: grid;
          place-items: center;
          width: #{$itemSize};
          min-height: #{$itemSize};
          font-weight: 300;
        }

        // Scrollbar
        &::-webkit-scrollbar {
          width: 5px;
          background: transparent;
        }
        &::-webkit-scrollbar-thumb {
          background: transparent;
        }
        &:hover::-webkit-scrollbar {
          background: #fafafa;
        }
        &:hover::-webkit-scrollbar-thumb {
          background: #ccc;
        }
      }
    }

    // Fade out disabled items.
    .item:not(.enabled),
    .date-content .item:not(.current-month) {
      color: #bbb;
      font-weight: 200;
    }

    // Yellow solid circle for selected items.
    .item {
      cursor: default;

      // Solid circle for selected and selected hovered.
      &.selected,
      &.enabled:hover {
        position: relative;
        cursor: pointer;

        &::before {
          position: absolute;
          content: '';
          left: 1px;
          top: 1px;
          right: 1px;
          bottom: 1px;
          border-radius: 50%;
          z-index: -1;
        }
      }

      // Default yellow color.
      &.selected::before {
        background-color: $brand-yellow-1;
      }

      // Set color to ligher hellow when other items than selected are hovered.
      &.enabled:not(.selected):hover::before {
        background-color: $brand-yellow-3;
      }

      // Red circle if selected item is disabled.
      &.selected:not(.enabled) {
        color: #fff;
        &::before {
          background-color: tomato;
        }
      }
    }
  }
}
