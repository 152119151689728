.one-row-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.multi-row-text {
  white-space: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  //line-height: normal
}

.red {
  color: $brand-red;
}

.green {
  color: $brand-green;
}

.gray {
  color: $brand-gray-3!important;
}

.margin {
  margin: $default-indent;
}

.margin-top {
  margin-top: $default-indent;
}

.margin-bottom {
  margin-bottom: $default-indent;
}

.margin-left {
  margin-left: $default-indent;
}

.margin-right {
  margin-right: $default-indent;
}

.brand-color {
  background-color: $brand-yellow-3 !important;
  border-radius: 10px;
}

.brand-hover {

  &[disabled] {
    color: $brand-gray-3!important;
  }

  &:hover:not([disabled]) {
    background-color: $brand-yellow-1 !important;
    transition: background-color 0.2s ease-in-out;
    border-radius: 10px;
  }
}

.mail-center {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
