// Hide Google Translation element (displayed at top in viewport).
#google_translate_element {
  display: none;
}
.skiptranslate {
  display: none !important;
}
#goog-gt-tt {
  display: none !important;
}
.goog-tooltip {
  display: none !important;
}
.goog-tooltip:hover {
  display: none !important;
}
.goog-text-highlight {
  background-color: transparent !important;
  border: none !important;
  box-shadow: none !important;
}
body {
  top: 0px !important;
}
