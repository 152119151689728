// Include variables
@import 'variables';

// Include all of Ionic
@import 'ionic/scss/ionic';

// Other third-party styles
@import '~angular-toastr/dist/angular-toastr.min.css';
@import 'components/dxf_fonts';
@import 'components/leaflet';

// Custom styles for components
@import 'components/button';
@import 'components/card';
@import 'components/grid';
@import 'components/flex-grid';
@import 'components/form';
@import 'components/spinner';
@import 'components/validation';
@import 'components/utils';
@import 'components/tab';
@import 'components/checkbox';

@import 'layout';
@import 'style';

@import 'google_translation';

// Necessary overrides for default Ionic styles
// NOTE: Prefer to customize components using SASS variables when possible (see
// variables.scss file).
.item-divider {
  // Dividers don't need so much height.
  min-height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 1px 0;
}
.button {
  // Weirdly computed values, which break custom styles.
  min-width: auto;
  min-height: auto;
}
// TODO: Figure out why text is cut in the control and apply proper fix
.item-checkbox .item-content {
  overflow: visible;
}
