.card {
  // Remove any margins from cards to make them more flexible.
  margin: 0;

  .card-header {
    background-color: $dark;
    color: white;
    font-weight: bold;

    border-top-left-radius: 2px;
    border-top-right-radius: 2px;

    padding: $default-indent;
  }
  .card-header.large {
    font-size: $font-size-large
  }

  .card-content {
    padding: $default-indent;
  }
}
