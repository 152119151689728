@import '../../../../src/scss/variables';

.searchable-drowdown-container {
  --font-family: $font-family-header;

  position: relative;
  z-index: $searchable-dropdown-z;

  * {
    // todo: Bad, but unfortunately we don't use a reset stylesheet.
    font-size: 11pt !important;
  }

  &.monospace {
    --font-family: 'monospace';
  }

  &.opened div {
    display: block;
  }

  &[data-disabled='true'] {
    opacity: 0.5;
    pointer-events: none;
  }

  input {
    position: relative;
    width: 100%;
    padding: 16px;
    padding-right: 45px !important;
    border: 1px solid;
    font-family: var(--font-family);

    &:read-only {
      cursor: pointer;
    }
  }

  span {
    display: block;
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    padding: 0 1em;
    cursor: pointer;
    display: flex;
    align-items: center;
    z-index: $searchable-dropdown-z;
  }

  div {
    position: absolute;
    display: none;
    width: 100%;
    bottom: calc(100% + 1px); /* Default bottom */

    &[alignment='below'] {
      top: calc(100% + 1px);
    }
    &[alignment='above'] {
      bottom: calc(100% + 1px);
    }

    ul {
      list-style-type: none;
      margin: 0;
      padding: 0;
      max-height: 180px;
      overflow-x: hidden;
      overflow-y: auto;
      border: 1px solid;

      li {
        font-family: var(--font-family);
        display: block;
        padding: 10px;

        &:not(.no-results)[data-enabled='true'] {
          cursor: pointer;
        }
        &:not(.no-results)[data-enabled='false'] {
          cursor: not-allowed;
        }

        &.no-results {
          font-style: italic;
        }
      }
    }
  }

  /* Dark theme */
  // Extra space: https://github.com/angular/angular.js/issues/8132
  &[data-theme='dark'],
  &[data-theme='dark '] {
    input {
      color: #fff;
      background-color: $brand-gray-2;
      border-color: $brand-gray-2;
    }

    &.opened div span {
      background-color: #555;
    }

    span {
      color: #ccc;
    }

    &.opened span,
    &:hover span {
      color: #fff;
    }

    ul {
      color: #fff;
      background-color: $brand-gray-2;
      border-color: $brand-gray-2;

      li {
        background-color: inherit;

        &[data-enabled='false'] {
          color: #fff6;
        }

        &:not(.no-results)[data-enabled='true']:hover {
          color: #000;
          background-color: $brand-yellow-2;
        }
      }
    }
  }

  /* Light theme */
  // Extra space: https://github.com/angular/angular.js/issues/8132
  &[data-theme='light'],
  &[data-theme='light '] {
    input {
      color: #000;
      background-color: #fff;
      border-color: $brand-gray-3;
    }

    span {
      color: #777;
    }

    &.opened span,
    &:hover span {
      color: #000;
    }

    ul {
      color: #000;
      background-color: #fff;
      border-color: $brand-gray-3;

      li {
        background-color: inherit;
        border-color: inherit;

        &[data-enabled='false'] {
          color: #0006;
        }

        &:not(.no-results)[data-enabled='true']:hover {
          color: #000;
          background-color: $brand-yellow-2;
        }
      }
    }
  }
}
