@import './variables.scss';

body {
  height: 100vh;
  background-color: #eee;
}

[ui-view] {
  height: 100%;
}

.bar.bar-dark .title {
  background-image: url(/static/assets/img/navbar_logo.png);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  color: transparent;
  height: $size-6;
}

.skymap-body {
  position: relative;
  background-color: #fff;
  margin: 50px auto 20px auto;
  padding: 25px;
  width: clamp(600px, 75%, 1200px);
  box-shadow:
    0 1px 3px rgba(0, 0, 0, 0.12),
    0 1px 2px rgba(0, 0, 0, 0.24);

  &-full-size {
    $margin: 30px;
    width: calc(100% - 2 * $margin);
    margin: 50px $margin $margin $margin;
  }

  > h1:first-of-type {
    margin: 0 0 20px 0;
    padding: 0;
    text-transform: uppercase;
    letter-spacing: 0.1em;
    font-size: 14pt;
    font-weight: bold;
    color: #333;
  }
}

/* Modal windows */
.backdrop-black {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #00000060;
  z-index: $backdrop-z;
}

/* Sign In page */

.sign-in-logo {
  display: block;
  max-width: 400px;
  margin: 200px auto $default-indent;
}

.sign-in-form {
  max-width: 400px;
  margin: 0 auto;
}

/*   Container for page center form */
.center-page-form-container {
  max-width: 512px;
  margin: 0 auto;
}

/* Project Users page */

.skymap-container {
  background-color: $brand-yellow-3;
  border: 1px solid $brand-yellow-1;
  border-radius: 4px;
  padding: $default-indent;
}

// TODO: Figure out styles below

divider {
  width: 100%;
  border-bottom: 2px solid #aaa;
}

input[type='checkbox'] {
  cursor: pointer;
}

#mainLayout {
  height: 100vh;
  width: 100vw;

  &.topHeaderVisible {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto 1fr;
  }
}

#mainContent {
  overflow-y: auto;
}

//
// Top header.
//

header.topHeader {
  #mainLayout:not(.topHeaderVisible) & {
    display: none;
  }

  display: grid;
  background-color: var(--top-menu-background-color);

  box-shadow:
    0 1px 3px rgba(0, 0, 0, 0.12),
    0 1px 2px rgba(0, 0, 0, 0.24);
  z-index: 10000;

  &.logotypeOnly {
    place-content: center;

    img {
      content: var(--top-menu-logotype);
      height: 55px;
      margin: 0 50px 0 10px;
      padding: 10px 0;
    }
  }

  &:not(.logotypeOnly) {
    grid-template-columns: auto 1fr auto auto auto;
    grid-template-areas:
      'logotype top-menu notification-bell help-center user-profile'
      'sub-menu sub-menu sub-menu sub-menu sub-menu';
  }

  nav.top-menu {
    grid-area: top-menu;
    height: 100%;
    color: var(--top-menu-text-color);

    ul {
      display: flex;
      align-items: stretch;
      height: 100%;
      font-size: 12pt;

      li {
        &.logotype {
          grid-area: logotype;
          margin: 0;
          height: 100%;

          img {
            content: var(--top-menu-logotype);
            height: 2em;
          }
        }

        display: grid;
        place-items: center;
        padding: 5px 20px;
        cursor: pointer;

        &:hover {
          background-color: lighten($brand-gray-1, 8%);
          color: #fff;
        }

        &.active {
          background-color: $brand-gray-1;
          color: #fff;
        }
      }
    }
  }

  nav.sub-menu {
    grid-area: sub-menu;
    height: 100%;
    background-color: #fff;

    ul {
      display: flex;
      align-items: stretch;
      height: 100%;
      font-size: 12pt;

      li {
        display: grid;
        place-items: center;
        white-space: nowrap;
        padding: 10px 20px;
        cursor: pointer;
        color: #888;

        &:hover,
        &.active {
          color: #000;
          background-color: #eaeaea;
        }
      }
    }
  }

  .notification-bell {
    grid-area: notification-bell;
    display: flex;
    align-items: center;
  }

  .help-center {
    grid-area: help-center;
    display: flex;
    align-items: center;
    padding: 0 15px;
    text-decoration: none;

    &:hover {
      background-color: lighten($brand-gray-1, 8%);
      cursor: pointer;

      i {
        color: #fff;
      }
    }

    i {
      font-size: 24pt;
      color: var(--top-menu-text-color);
    }
  }
}

//
// Botton navigation
//
footer.navigation {
  z-index: 10;
  position: absolute;
  display: flex;
  justify-content: center;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #5e6a71;
  padding: 7px;

  ul {
    display: flex;
    align-items: center;
    gap: 80px;
    font-size: 10pt;

    li {
      padding: 3px;
      cursor: pointer;
      color: #ccc;

      &:hover a,
      &.active a {
        color: #fff;
      }

      a {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-decoration: none;
        color: #ccc;

        i {
          color: inherit;
          font-size: 1.8em;
        }

        span {
          margin-top: 5px;
        }
      }
    }
  }
}

/**
* angular-popeye
* A simple modal library for AngularJS applications

* @author Pathgather <tech@pathgather.com>
* @copyright Pathgather 2015
* @license MIT
* @link https://github.com/Pathgather/popeye
* @version 1.0.5
*/
// Stripped and modified.
body.popeye-modal-open {
  overflow: hidden;
}
.popeye-modal-container {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  z-index: 5;
  overflow-y: auto;
  background-color: rgba(0, 0, 0, 0.2);
  text-align: center;

  &::before {
    content: '';
    display: inline-block;
    height: 100%;
    vertical-align: middle;
  }

  .popeye-wrapper {
    display: inline-block;
    vertical-align: middle;
    max-width: 400px;
    background: white;
    text-align: left;
    border-radius: 5px;
    box-shadow:
      0 3px 6px rgba(0, 0, 0, 0.16),
      0 3px 6px rgba(0, 0, 0, 0.23);
    z-index: 2147483647;

    header {
      display: flex;
      padding: 20px;
      font-size: 1.1em;
      background-color: #eee;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;

      h2 {
        margin: 0;
      }

      span {
        flex-grow: 1;
        font-weight: 500;
      }

      a.popeye-close-modal {
        font-weight: 100;
        text-decoration: none;

        &::after {
          content: '\d7';
        }
      }
    }

    .popeye-modal {
      padding: 20px;

      footer {
        display: flex;
        justify-content: center;
        gap: 20px;
        margin-top: 40px;

        button {
          margin: 0;
          width: auto;
          min-width: 100px;
        }
      }
    }
  }
}
