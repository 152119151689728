#breadCrumbs {
  position: absolute;
  top: -28px;
  left: 0px;
  display: block;
  margin-bottom: 10px;

  ol {
    display: flex !important;

    li {
      // Only to not render white space.
      display: flex;
    }

    li::before {
      content: '/';
      margin: 0 10px;
    }

    li:first-child::before {
      display: none;
    }
  }
}
