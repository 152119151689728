//
// Displaying content as inline-block will result in white spaces between
// elements being rendered and taking up space. Having columns that sum
// up to 12 then are not guaranteed (depending on markup setup) to be 100%, but
// over 100%.
//
// There is an error also: 5/12 is not 42.6%.
//
// todo: Use flex-grid.scss instead.
//
@media(max-width: 500px) {
  [class*=' sm-'], [class*='sm-'] {
    display: inline-block;
  }
  .sm-12 {
    width: 100%;
  }
  .sm-11 {
    width: 91.6%;
  }
  .sm-10 {
    width: 83.3%;
  }
  .sm-9 {
    width: 75.0%;
  }
  .sm-8 {
    width: 66.6%;
  }
  .sm-7 {
    width: 58.3%;
  }
  .sm-6 {
    width: 50.0%;
  }
  .sm-5 {
    width: 42.6%;
  }
  .sm-4 {
    width: 33.3%;
  }
  .sm-3 {
    width: 25.0%;
  }
  .sm-2 {
    width: 16.6%;
  }
  .sm-1 {
    width: 8.3%;
  }
  .sm-hide {
    display: none;
  }
}

@media (min-width: 500px) and (max-width: 1024px) {
  [class*=' md-'], [class*='md-'] {
    display: inline-block;
  }
  .md-12 {
    width: 100%;
  }
  .md-11 {
    width: 91.6%;
  }
  .md-10 {
    width: 83.3%;
  }
  .md-9 {
    width: 75.0%;
  }
  .md-8 {
    width: 66.6%;
  }
  .md-7 {
    width: 58.3%;
  }
  .md-6 {
    width: 50.0%;
  }
  .md-5 {
    width: 42.6%;
  }
  .md-4 {
    width: 33.3%;
  }
  .md-3 {
    width: 25.0%;
  }
  .md-2 {
    width: 16.6%;
  }
  .md-1 {
    width: 8.3%;
  }
  .md-hide {
    display: none;
  }
}

@media (min-width: 1024px) and (max-width: 1600px) {
  [class*=' lg-'], [class*='lg-'] {
    display: inline-block;
  }
  .lg-12 {
    width: 100%;
  }
  .lg-11 {
    width: 91.6%;
  }
  .lg-10 {
    width: 83.3%;
  }
  .lg-9 {
    width: 75.0%;
  }
  .lg-8 {
    width: 66.6%;
  }
  .lg-7 {
    width: 58.3%;
  }
  .lg-6 {
    width: 50.0%;
  }
  .lg-5 {
    width: 42.6%;
  }
  .lg-4 {
    width: 33.3%;
  }
  .lg-3 {
    width: 25.0%;
  }
  .lg-2 {
    width: 16.6%;
  }
  .lg-1 {
    width: 8.3%;
  }
  .lg-hide {
    display: none;
  }
}

@media (min-width: 1600px) {
  [class*=' xl-'], [class*='xl-'] {
    display: inline-block;
  }
  .xl-12 {
    width: 100%;
  }
  .xl-11 {
    width: 91.6%;
  }
  .xl-10 {
    width: 83.3%;
  }
  .xl-9 {
    width: 75.0%;
  }
  .xl-8 {
    width: 66.6%;
  }
  .xl-7 {
    width: 58.3%;
  }
  .xl-6 {
    width: 50.0%;
  }
  .xl-5 {
    width: 42.6%;
  }
  .xl-4 {
    width: 33.3%;
  }
  .xl-3 {
    width: 25.0%;
  }
  .xl-2 {
    width: 16.6%;
  }
  .xl-1 {
    width: 8.3%;
  }
  .xl-hide {
    display: none;
  }
}

// Special row, which only adds padding between rows and cells.
// This is more convenient to use compared to the default one, which adds
// padding everywhere.
.row-inner-padding {
  padding: 0 0 $default-indent;

  &:last-of-type {
    padding-bottom: 0;
  }

  > .col {
    padding-top: 0;
    padding-bottom: 0;
    margin-bottom: 0;
  }

  > .col:first-child {
    padding-left: 0;
  }

  > .col:last-of-type {
    padding-right: 0;
  }

  &.responsive-sm {
    > .col {
      @media (max-width: $grid-responsive-sm-break) {
        padding: 0;
        margin-bottom: $default-indent;
      }
    }

    > .col:last-of-type {
      margin-bottom: 0;
    }
  }
  &.responsive-md {
    > .col {
      @media (max-width: $grid-responsive-md-break) {
        padding: 0;
        margin-bottom: $default-indent;
      }
    }

    > .col:last-of-type {
      margin-bottom: 0;
    }
  }
  &.responsive-lg {
    > .col {
      @media (max-width: $grid-responsive-lg-break) {
        padding: 0;
        margin-bottom: $default-indent;
      }
    }

    > .col:last-of-type {
      margin-bottom: 0;
    }
  }
}

.row-inner-padding + .row-inner-padding {
  margin-top: 0;
}
