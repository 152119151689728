@import '../../../../src/scss/variables';

#skyview-loader {
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: #ccddf0;
  font-size: 12pt;

  h1 {
    margin: 0 0 30px 0;
    font-size: 20pt;
  }

  div.error {
    span:first-of-type {
      display: block;
      color: #a00;

      i {
        margin-right: 10px;
      }
    }

    span:last-of-type {
      display: block;
      margin-top: 20px;
      font-style: italic;
      line-height: 1.3em;
      font-size: 0.95em;
    }
  }
}
