@import '../../../../../src/scss/variables';

#overviewContainer {
  background: #ccddf0;
  border-radius: 4px;
  height: 100%;
  overflow: hidden;
  margin-left: 8px;
  user-select: none;

  #overviewMap {
    margin: 200px;
    position: relative;

    .thumbnail-enabled {
      position: absolute;
      width: 74px;
      height: 50px;
      pointer-events: none;

      &.focus {
        z-index: 2;
      }

      div {
        width: 74px;
        height: 50px;
        background: $brand-gray-2;
        position: absolute;
        border: 1px solid $brand-gray-2;
        left: -50%;
        top: -50%;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        pointer-events: all;

        &:hover {
          border-color: $brand-yellow-1;
        }

        &.unpinned {
          border-color: #3f4ed3 !important;
        }

        &.pinned {
          border-color: #afda6f !important;
        }

        &.selected {
          border: 2px solid $brand-yellow-1 !important;
          z-index: 2;
        }

        &.filtered {
          border-width: 6px 6px !important;
        }
      }
    }

    .thumbnail-disabled {
      position: absolute;
      width: 15px;
      height: 15px;

      div {
        width: 15px;
        height: 15px;
        background: $brand-gray-2;
        border-radius: 5px;
        position: absolute;
        left: -50%;
        top: -50%;

        &.not-aligned {
          background: $brand-red !important;
        }
      }
    }

    .gcp {
      position: absolute;
      width: 15px;
      height: 15px;
      z-index: 20;
      pointer-events: none;

      div {
        pointer-events: all;
        width: 20px;
        height: 20px;
        background: rgb(0, 169, 224);
        border: solid black 3px;
        border-radius: 13px;
        position: absolute;
        left: -50%;
        top: -50%;

        &:hover {
          background: $brand-yellow-1;
        }

        &.selected {
          background: $brand-yellow-1;
        }
      }

      span {
        pointer-events: none;
        position: absolute;
        margin-top: 18px;
        background: rgba(255, 255, 255, 0.7);
        border-radius: 2px;
      }
    }

    .loaded {
      border-color: $brand-green !important;
      border-width: 3px !important;
      z-index: 1;
    }
  }
}
