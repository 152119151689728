@import '../../../../src/scss/variables';

.orthophoto-comparison-dialog {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;

  > div {
    position: absolute;
    top: 60px;
    right: 60px;
    bottom: 60px;
    left: 60px;
    border: 1px solid #aaa;
    border-radius: 8px;
    box-shadow:
      0 3px 6px rgba(0, 0, 0, 0.16),
      0 3px 6px rgba(0, 0, 0, 0.23);
    overflow: hidden;
    display: flex;
    flex-direction: column;

    > header {
      display: grid;
      grid-template-columns: 1fr auto auto auto 1fr;
      grid-column-start: 2;
      gap: 50px;
      align-items: center;
      padding: 8px 0;
      background-color: #d1d4d3;
      border-bottom: 1px solid #555;

      // Make first child (dropdown) start from second cell.
      // This will center the content correctly.
      // The first and last cell (close icon) will have the same space (1fr).
      > :first-child {
        grid-column-start: 2;
      }

      // Headline
      > span {
        font-size: 1.2em;
        font-weight: 400;
        align-self: center;
        white-space: nowrap;
      }

      // Close icon.
      > i {
        justify-self: end;
        padding: 10px;
        margin-right: 10px;
        font-size: 12pt;
        cursor: pointer;
        border-radius: 4px;

        &:hover {
          background-color: #0002;
        }

        &:active {
          background-color: #0004;
        }
      }
    }

    > article {
      position: relative;
      height: 100%;

      // Loader overlay
      > section {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #eee;
        z-index: 1;
        display: flex;
        gap: 20px;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .center-text {
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 20px;

          i {
            font-size: 60pt;
            color: #0006;
          }
        }
      }
    }
  }
}
