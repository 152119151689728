@import '../../../../src/scss/variables';

nav.skyview-contextmenu {
  display: none;
  position: absolute;
  box-shadow: 0 4px 5px 3px rgba(0, 0, 0, 0.2);
  z-index: 2147483647;

  &.visible {
    display: block;
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      position: relative;
      font-weight: 500;
      font-size: 11pt;
      padding: 10px 40px 10px 20px;
      color: #555;
      cursor: pointer;

      &:hover::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 4px;
        background-color: $brand-yellow-1;
      }

      &:hover {
        background: $brand-yellow-3;
      }
    }
  }
}
