#gui {
  position: absolute;
  top: 2px;
  right: 2px;
}

/**
  h# titles scss overrides
**/
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Montserrat', sans-serif;
}

/* PROGRESS BAR START */
div#progressbar {
  position: relative;
  width: 100%;
  height: 30px;
  background-color: grey;
}
div#progressbar > div {
  position: absolute;
  width: 1%;
  height: 100%;
  background-color: green;
}

div#progressbar > div > div {
  text-align: center; /* If you want to center it */
  line-height: 30px; /* Set the line-height to the same as the height of the progress bar container, to center it vertically */
  color: white;
}
/* PROGRESS BAR END */

a.gui-item {
  padding-bottom: 8px;
  padding-top: 8px;
}

.logo {
  padding: 1em;
  width: 80%;
}

.no-padding {
  padding: 0px !important;
}

.angular-leaflet-map {
  height: 200px;
  width: 100%;
}

.list.card > .angular-leaflet-map {
  height: 200px;
  width: 100%;
}
.col > .angular-leaflet-map {
  height: 300px;
  width: 100%;
}

.leaflet-bottom {
  bottom: 10px;
}

.card .header {
  border-bottom: solid 1px black;
  margin: 8px;
}

.card .header .icon {
  font-size: 90px;
  display: inline-block;
  padding-left: 10px;
}

.card .header .title {
  display: inline-block;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  padding-left: 10px;
}

.label-value textarea,
.label-value input[type='text'] {
  padding: $size-1 * 1.5;
  border: 1px solid $brand-gray-4;
}

.divider {
  padding: 16px;
}

.hide {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.nv-file-over {
  background-color: #ffbea3;
}

.other-drop-zone {
  border: 2px dashed burlywood;
  padding: 4px;
  height: 100px;
}

.other-over-zone {
  background-color: moccasin;
}

.bg {
  background-color: lightgreen;
}

.over-zone {
  border: 2px dashed lavender;
  height: 100px;
  padding: 4px;
}

.box {
  margin: 20px;
}

.uploaded {
  background-color: lightgreen;
  height: 20px;
  width: 100px;
}

ul.color-alternating-rows > li:nth-child(odd) {
  background-color: #f5f5f5;
}

.hidden {
  display: none;
}

.zone {
  width: 49%;
}

div.row label.wide input {
  width: 98%;
}

label.no-border {
  border-style: none;
}

.project-title {
  margin-top: 1em;
  position: fixed;
  width: 50%;
  left: 50%;
}

.skymap-button {
  cursor: pointer;
  border-radius: 2px;

  padding-left: 4px;
  padding-right: 4px;

  transition: all 0.3s;

  color: black;
}

.skymap-button:hover {
  background-color: rgb(64, 43, 64);

  box-shadow: 0px 0px 10px rgb(64, 43, 64);

  color: white;
}

.project-corner-edit {
  position: absolute;
  right: 10px;
  bottom: 2px;
}

.project-corner-lettercount {
  position: absolute;
  right: 28px;
  bottom: 4px;
  font-size: 14px;
  color: $brand-gray-1;
}

.project-edit-field-multirow {
  width: 95%;
  height: 110px;
  overflow: hidden;
}

.project-description-box {
  height: 185px;
}

.project-description-container {
  display: inline-block;
  line-height: 120%;
  font-family: 'Montserrat';
  margin-bottom: 20px;
}

.radio-content.radio-thin > .item-content {
  padding: 6px 64px 6px 16px;
}

.radio-thin > i.radio-icon {
  padding: 0px 14px 14px 14px;
}
input[type='radio']:disabled + .radio-content > .item-content {
  background: #f7f7f7 !important;
  /*color: #ff6666;
  text-decoration: line-through;*/
}

.radio-visible {
  visibility: visible !important;
}

.radio-background {
  background: #f7f7f7 !important;
}

/*
  Modal css
*/

@media screen and (min-width: 680px) {
  .skymap-modal {
    top: 40% !important;
    left: 30% !important;

    width: 40% !important;
    height: 20% !important;

    border-radius: 8px;
  }

  .skymap-modal-tall {
    top: 20% !important;
    left: 30% !important;

    width: 40% !important;
    height: 30% !important;

    border-radius: 8px;
  }
}

/**
* Link colours
*/
a {
  color: $brand-gray-1;
}

/*
* Toaster settings
*/
#toast-container {
  z-index: 10002; /* Make sure this is higher than Intercom launcher frame. */
}
.toast-info {
  background-color: $brand-blue;
}
.toast-error {
  background-color: $brand-red;
}
.toast-success {
  background-color: $brand-green;
}
.toast-warning {
  background-color: $brand-yellow-1;
}

/*
* Intercom 
*/
.intercom-lightweight-app {
  z-index: 10000 !important;
}
.intercom-launcher-frame {
  z-index: 10000 !important;
}
.intercom-launcher-badge-frame {
  z-index: 10001 !important;
}
.intercom-messenger-frame {
  z-index: 10000 !important;
}

//
// Styling of bread crumb.
// <span>Folder A</span><span>Folder B</span><span>Folder C</span>
//
// Use margin-right for both span and / in order to horizontally align / when line breaks.
//
.bread-crumb span {
  display: inline-block;
  margin-right: 0.5em;

  &:last-child {
    font-weight: bold;
  }

  &:not(:first-child)::before {
    content: '\f105';
    font-family: 'FontAwesome';
    margin-right: 0.5em;
    color: #aaa;
  }
}
