@import '../../../../src/scss/variables';

.image-viewer {
  --progress: 0%;

  position: relative;
  width: 100%;
  height: 100%;
  background-color: #fff;

  section.center-content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  svg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
