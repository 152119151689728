/*
  CSS for the component spinner
*/

.spinner-container {
  padding: 8px;
}

.spinner-container.big {
  padding: 16px;
}

spinner.left {
  float: left;
}

spinner.right {
  float: right;
}

spinner.center {
  display: flex;
  align-items: center;
  justify-content: center;
}
