@import '../../../../src/scss/variables';

#geodataUpload {
  overflow: auto;
  min-height: 100vh;
  background-color: #fff;

  a.third-party-reference {
    position: absolute;
    right: 30px;
    bottom: 30px;

    font-family: $font-family-header;
    font-size: 12pt;
    color: #3366bb;

    opacity: 0.5;

    &:hover {
      opacity: 1;
    }
  }
}
